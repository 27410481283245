@import url('https://fonts.googleapis.com/css2?family=El+Messiri&family=PT+Sans+Narrow&family=PT+Sans:wght@400;700&display=swap');

* {
  margin: 0;
  padding: 0;
}

body {
  background: #fff;
  color: #222;
  font-family: 'PT Sans', sans-serif;
  font-weight: 400;
  font-size: 18px;
  padding-left: 1%;
  padding-right: 1%;
  min-width: 690px;
  max-width: 1380px;
  margin: 0 auto;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: #222;
}

.wrapper {
  margin: 10px auto;
  overflow: hidden;
}

header {
  position: relative;
}

header .shop-cart-button {
  cursor: pointer;
  transform: scale(1.5);
  transition: color, transform 500ms ease;
}

header .shop-cart-button:hover,
header .shop-cart-button.active {
  color: #dc3d3d;
  transform: scale(1.7);
}

.shop-cart {
  position: fixed;
  top: 40px;
  right: 15px;
  width: 450px;
  max-height: 1000px;
  overflow-y: auto;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(5px);
  -webkit-box-shadow: 4px 5px 15px -7px #606060;
  box-shadow: 4px 5px 15px -7px #606060;
  z-index: 5000;
  padding: 20px;
  padding-bottom: 0;
}

.shop-cart .empty h2 {
  font-size: 20px;
  margin-bottom: 20px;
}

.shop-cart .item {
  width: 100%;
  float: left;
  margin-bottom: 20px;
}

.shop-cart .item img {
  width: 70px;
  float: left;
  margin-right: 20px;
  background: rgba(255, 255, 255, 0);
}

.shop-cart .item h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.shop-cart .item b {
  color: #797979;
  font-weight: 700;
}

.shop-cart .item .delete-icon {
  color: #ca5252;
  float: right;
  position: relative;
  top: -25px;
  right: -45px;
  cursor: pointer;
  transform: scale(1.2);
}

.shop-cart .item .delete-icon:hover {
  color: #000;
  transform: scale(1.6);
  transition: color, transform 500ms ease;
}

.shop-cart .quantity {
  float: right;
  position: relative;
  font-weight: 700;
  top: -27px;
  width: 39px;
  text-align: center;
}

.shop-cart .summa {
  float: left;
  width: 100%;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 20px;
}

header .presentation {
  margin-top: 75px;
  width: 100%;
  height: 790px;
  overflow: hidden;
}

header .teona {
  position: relative;
  width: 100%;
  height: 790px;
  overflow: hidden;
}

header .teona::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('./img/banner-lowres.webp') no-repeat center center;
  background-size: cover;
  filter: blur(8px); /* Степень размытия */
  z-index: -1; /* Чтобы размытие было позади контента */
}

header .teona img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 790px;
  transform: translate(-50%, -50%);
  object-fit: cover;
  filter: blur(10px); /* Начальное размытие */
  opacity: 0; /* Начальная непрозрачность */
  transition: filter 0.25s ease, opacity 0.25s ease; /* Плавный переход для размытия и непрозрачности */
}

header .teona img.loaded {
  filter: blur(0); /* Убираем размытие */
  opacity: 1; /* Делаем изображение видимым */
}

@font-face {
  font-family: 'slogan';
  src: url('./font/Sweet Mavka Script.woff') format('woff');
  font-weight: 100;
}

header .presentation .slogan {
  visibility: hidden;
  font-family: slogan;
  position: absolute;
  top: 180px;
  left: 35px;
  width: 460px;
  font-size: 24px;
  line-height: 40px;
  font-weight: 500;
  color: #222;
  display: block;
}

@media (min-width: 830px) {
  header .presentation .slogan {
    visibility: visible;
    left: 25px;
    width: 210px;
  }
}

@media (min-width: 1000px) {
  header .presentation .slogan {
    visibility: visible;
    width: 320px;
  }
}

@media (min-width: 1300px) {
  header .presentation .slogan {
    visibility: visible;
    width: 430px;
  }
}

.about {
  font-family: 'El Messiri', sans-serif;
  line-height: 2ch;
  margin-top: 50px;
  text-align: left;
  column-count: 1;
  column-gap: 10px;
}
@media (min-width: 900px) {
  .about {
    column-count: 2;
  }
}

.advert {
  background-color: #dc3d3d;
  color: #f5f5f5;
  padding-left: 10px;
  margin-bottom: 5px;
  height: 40px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

menu {
  z-index: 3000;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.9) 25%,
    rgba(255, 255, 255, 1) 100%
  );
  padding-top: 20px;
  padding-bottom: 20px;
  min-width: 730px;
  hyphens: none;
  position: fixed;
  top: 0;
  width: 98%;
}

@media (min-width: 1381px) {
  menu {
    max-width: 1380px;
  }
}

menu .logo {
  display: inline-block;
  font-weight: 700;
  font-size: 1.25em;
}

menu ul.nav {
  font-size: 1.25em;
  float: right;
  list-style: none;
}

menu ul.nav li {
  display: inline-block;
  margin-left: 1em;
  position: relative;
  cursor: pointer;
  transition: opacity 500ms ease;
}

menu ul.nav li:hover {
  opacity: 0.5;
}

.slider-nav {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 5px;
  transform: scale(0.75);
}

.slider-nav-item {
  margin-inline: 10px;
  cursor: pointer;
  transition: all 500ms ease;
  color: #222;
}

.slider-nav-item.active {
  color: #f2f2f2;
}

.slider-nav-item:hover {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  color: #606060;
}

.slider-full-opacity {
  background: rgba(0, 0, 0, 1);

  left: 0px;
  top: 0px;
  width: 100%;
  height: 790px;
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
}

.video-container {
  max-width: 430px;
}

.video-container video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.categories div {
  display: inline-block;
  background: #f2f2f2;
  border-radius: 15px;
  padding: 10px 20px;
  margin-left: 5px;
  margin-top: 55px;
  margin-bottom: 25px;
  margin-right: 15px;
  cursor: pointer;
  border: 1px solid transparent;
  transition: all 500ms ease;
}

.categories div:hover {
  border-color: silver;
  transform: scale(1.1);
}

.cart-full-bg {
  min-height: 100%;
  min-width: 100%;
  background-color: red;
}

.full-item {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
  z-index: 9999;
  overflow: auto;
}

.full-item > div {
  width: 600px;
  position: relative;
  margin: 10% auto;
  padding: 40px 30px;

  background: #fff;
}

main {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 0.5em;
}

main .item {
  background: #f5f5f5;
  overflow: hidden;
  position: relative;
  padding-bottom: 20px;
}

main .item img,
.item picture,
.full-item img,
.full-item picture {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: auto;
  transition: transform 500ms ease;
}

.full-item img,
.full-item picture {
  width: 100%;
}

main .item img:hover,
.item picture:hover {
  transform: scale(1.05);
  cursor: pointer;
}

main h2,
.full-item h2,
main p,
.full-item p,
main b,
.full-item b {
  margin: 10px 20px;
  color: #333;
}

.notes-list {
  list-style: none; /* Убираем маркеры списка */
  padding: 0; /* Убираем отступы */
  margin: 0 0 1rem 0; /* Установка отступа снизу */
}

.notes-list em {
  font-family: 'PT Sans Narrow', sans-serif;
  color: #999;
  letter-spacing: -0.25px;
}

.notes-list li {
  margin-bottom: 0.5rem; /* Отступ между элементами списка */
}

.item-price {
  margin-top: 3rem;
}

main em {
  display: inline-block;
  font-weight: 400;
  font-style: normal;
  margin-top: 0px;
}

.more {
  font-family: 'PT Sans Narrow';
  color: #dc3d3d;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
  transition: all 500ms ease;
}

.more:hover {
  color: #222;
  transition: 0.5s;
  letter-spacing: 2px;
}

em {
  display: block;
  margin-top: 10px;
  font-style: normal;
  font-weight: 700;
}

.gender {
  float: right;
  vertical-align: top;
}

.item-info {
  margin: 20px;
}

main b {
  position: absolute;
  bottom: 15px;
  color: #ca5252;
}

main .price-old {
  color: #222;
  text-decoration: line-through;
}

.full-item b {
  color: #ca5252;
}

main .price {
  position: absolute;
  right: 20px;
  top: 530px;
}

main .add-to-cart,
.full-item .add-to-cart {
  position: absolute;
  right: 0px;
  bottom: 0px;
  background: #ca5252;
  width: 70px;
  height: 70px;
  color: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: xx-large;

  cursor: pointer;
  font-weight: 700;
  transition: all 500ms ease;
}

main .add-to-cart:hover,
.full-item .add-to-cart:hover {
  background-color: #000000;
  transition: 0.5s;
  font-size: xxx-large;
}

.add-to-cart-full {
  background: #ca5252;
  height: 50px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  cursor: pointer;
  font-weight: 700;
  transition: all 500ms ease;
  width: 490px;
}

.add-to-cart-full:hover {
  background-color: #000000;
  font-size: x-large;
  transition: 0.5s;
}

.copy-link {
  background: #ca5252;
  height: 50px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  margin-right: 15px;
}

.copy-link:hover {
  background-color: #000000;
  font-size: x-large;
  transition: 0.5s;
}

.close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  background: #fff;
  width: 35px;
  height: 35px;
  color: #ca5252;
  border-radius: 50%;
  cursor: pointer;
  transition: all 500ms ease;
}
.close-button:hover {
  transform: scale(1.2) rotate(180deg);
  color: #000;
}

.fa-button {
  position: absolute;
  top: 425px;
  background: transparent;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 35px;
  color: #fff;
  cursor: pointer;
  transition: all 500ms ease;
}
.fa-button:hover {
  transform: scale(1.5);
  color: #000000;
}

.shop-cart {
  position: fixed;
  top: 40px;
  right: 15px;
  width: 525px;
  max-height: 1000px;
  overflow-y: auto;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(5px);
  -webkit-box-shadow: 4px 5px 15px -7px #606060;
  box-shadow: 4px 5px 15px -7px #606060;
  z-index: 5000;
  padding: 20px;
  padding-bottom: 0;
}

input {
  width: 100%;
  height: 25px;
  margin-bottom: 10px;
  background-color: #f2f2f2;
  border: none;
}

textarea {
  width: 100%;
  height: 150px;
  background-color: #f2f2f2;
  border: none;
  resize: none;
}

footer {
  font-size: 18px;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 50px;
}

.icon {
  transform: scale(2);
  margin-top: 50px;
  margin-inline: 25px;
  transition: all 500ms ease;
}

.icon:hover {
  color: #606060;
  transform: scale(2.5);
}

.badge {
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  color: #f2f2f2;
  background-color: #dc3d3d;
  border-radius: 50%;
  padding-left: 9px;
  padding-right: 9px;
  position: absolute;
  top: -50%;
  right: -45%;
}
